import React, { useState, forwardRef, useCallback } from 'react'; 
import { useSnackbar, SnackbarContent } from 'notistack';  
import {  
    IconButton,
    CardActions,
    Card,
    Collapse,
    Typography,
    Paper,
    // useTheme
  } from '@mui/material'; 
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'; 
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; 


const SnackMessage = forwardRef((props, ref) => { 
    // const theme = useTheme(); 
const styles = {
    card: {
        backgroundColor: '#CB3C1D', // CB3C1D 
        width: '100%',
    },
    error: {
        backgroundColor: '#ff0000',
        width: '100%',
    },
    typography: {
        fontWeight: 'bold',
        color: '#ede8cc'
    },
    actionRoot: {
        padding: '8px 8px 8px 16px',
        justifyContent: 'space-between',
    },
    icons: {
        marginLeft: 'auto',
        alignItems: 'space-between',
        justifyContent: 'space-between'
    },
    expand: {
        color: '#ede8cc',
        padding: '8px 8px',
        transform: 'rotate(0deg)', 
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    collapse: { 
        backgroundColor: '#f5dd8c',
        width: '100%',
        padding: 16,
    },
    checkIcon: {
        fontSize: 20,
        color: '#b3b3b3',
        paddingRight: 4,
    },
    button: {
        padding: 0,
        textTransform: 'none',
    }
}
  
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState((typeof props.message === 'string') ? false : (props.message?.messages.length > 0) );

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
        closeSnackbar(); 
    }, [props.id, closeSnackbar]);
    let title = null;
    let cardStyle = styles.card;
    let messages = [];
    if(typeof props.message === 'string')
        title = props.message;
    else {
        title = props.message.title; 
        if(props.message.messages.length > 0){
            messages = [...props.message.messages]
        } 
        if(props.message?.variant){
            cardStyle = styles[props.message?.variant];
        }
    }

    return (
        <SnackbarContent ref={ref} severity='info' >
            <Card style={cardStyle}  >
                <CardActions style={styles.actionRoot} >
                    <Typography variant="subtitle2" style={styles.typography}  >{title}</Typography>
                    <div style={styles.icons}  >
                        <IconButton
                            aria-label="Show more" 
                            onClick={handleExpandClick}
                            style={{
                                color: '#ede8cc'
                            }}
                        >
                            {((typeof props.message === 'string') ? false : (props.message?.messages.length > 0) ) && expanded  && <ExpandMoreIcon />}
                            {((typeof props.message === 'string') ? false : (props.message?.messages.length > 0) ) && !expanded && <ExpandLessIcon/>}
                        </IconButton>
                        <IconButton style={styles.expand} 
                        onClick={handleDismiss}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Paper style={styles.collapse} >
                        {messages.map((msg) => { 
                            return <Typography gutterBottom>{msg}</Typography>
                        })} 
                    </Paper>
                </Collapse>
            </Card>
        </SnackbarContent>
    );
});

export default SnackMessage;