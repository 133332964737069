/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable prefer-template */
/* eslint-disable no-useless-concat */
import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import axios  from 'axios';  
import { useEnqueuSnackbar } from "src/utils/tools/useEnqueueSnackbar";
// import popOver from 'src/utils/tools/popOver';
// import {Signer} from '@aws-amplify/core';
// import { Sha256 } from "@aws-crypto/sha256-js";
// import { defaultProvider } from "@aws-sdk/credential-provider-node";
// import { HttpRequest } from "@aws-sdk/protocol-http";
// import { SignatureV4 } from "@aws-sdk/signature-v4";
import Amplify, { Auth } from 'aws-amplify'; 
import { useTranslation } from 'react-i18next';
import awsconfig from '../aws-exports';
 

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  UPDATE_USER_DATA: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state) => ({ ...state }),
  VERIFY_CODE: (state) => ({ ...state }),
  RESEND_CODE: (state) => ({ ...state }),
  PASSWORD_RECOVERY: (state) => ({ ...state }),
  PASSWORD_RESET: (state) => ({ ...state })
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialAuthState,
  method: 'Amplify',
  updateUserData: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const enqueueSnackbar = useEnqueuSnackbar();
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [shown, setShown] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const initialize = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: {
              id: user.sub,
              jobtitle: 'General User',
              avatar: user.picture,
              email: user.attributes.email,
              username: user.username, 
              worker: null,
              sessionToken: null
            }
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);
 
  const betaMessages = () => {
    // popOver.enqueueSnackbar('Report SNAFU');
    enqueueSnackbar({
      title: t('Thanks for trying out our beta!'),
      messages: [ 
        t('Some operations may auto log you out,'), 
        t('For issues please contact dev@halfnhalf.app'),
      ]},  {
        autoHideDuration: 15000, 
      });
      
  }
  const updateUserData = async (user) =>{
    dispatch({
      type: 'UPDATE_USER_DATA',
      payload: { 
        user
      }
    });
    return user
  }

  const reFetchUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();console.log("***************USERDATA*****************");
      console.log(user)
      
      var config = {
        method: 'get',
        url: 'https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/user?id=' + user.attributes.sub,
        headers: { 
          'Accept': '*/*',
          'Authorization': `Bearer ${user.signInUserSession.idToken.jwtToken}`
        }
      };
  
      let wrker = {
        id: null,
        store: null
      };
      let profile = null;
      let metaData = null;
      let roles = [];
      
      let resp = await axios(config).catch(() => {
        console.error(`Can't login, error reaching backend.`);
        return {
          error: true
        }
  
      });
  
      if(resp.error){
         
        return {
          error: true
        }
      }
      
      console.log(resp);
      
      if(resp.status === 200){
        if(resp.data.data.id){
          wrker.id = resp.data.data.id;
          wrker.store = null;
          if(resp.data.data.storeData){
            wrker.store = resp.data.data.storeData;
          }
        }
  
        profile = resp.data.data.profile;
        metaData = resp.data.data.metaData;
        roles = [...resp.data.data.roles];
      }
       
      if(resp.status !== 200 || resp.status === null){
        console.error(`Can't login, error reaching backend.`);
        dispatch({
          type: 'LOGOUT'
        }); 
        return {
          error: true
        }
      }
      
      if (user.challengeName) {
        console.error(`Can't login, "${user.challengeName}" failed.`); 
        return {
          error: true
        }
      }
      let userPayload = {
        id: user.attributes.sub,
        jobtitle: user.attributes.email,
        avatar: user.picture,
        email: user.attributes.email,
        username: user.username, 
        worker: wrker,
        sessionToken: user.signInUserSession.idToken.jwtToken,
        profile,
        metaData,
        roles
      }; 
      // console.log(userPayload)
       
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {...userPayload}
        }
      });
    } catch (error) {
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
    return {
      error: false
    }
  }

  const login = async (email, password, localSave = false) => {
    

    const user = await Auth.signIn(email, password);
    

    console.log("***************USERDATA*****************");
    console.log(user)
    
    var config = {
      method: 'get',
      url: 'https://n3qwo6m3p4.execute-api.ca-central-1.amazonaws.com/beta/v1/user?id=' + user.attributes.sub,
      headers: { 
        'Accept': '*/*',
        'Authorization': `Bearer ${user.signInUserSession.idToken.jwtToken}`
      }
    };

    let wrker = {
      id: null,
      store: null
    };
    let profile = null;
    let metaData = null;
    let roles = [];
    
    let resp = await axios(config).catch(() => {
      console.error(`Can't login, error reaching backend.`);
      return {
        error: true
      }

    });

    if(resp.error){
       
      return {
        error: true
      }
    }
    
    console.log(resp);
    
    if(resp.status === 200){
      if(resp.data.data.id){
        wrker.id = resp.data.data.id;
        wrker.store = null;
        if(resp.data.data.storeData){
          wrker.store = resp.data.data.storeData;
        }
      }

      profile = resp.data.data.profile;
      metaData = resp.data.data.metaData;
      roles = [...resp.data.data.roles];
    }
     
    if(resp.status !== 200 || resp.status === null){
      console.error(`Can't login, error reaching backend.`);
      dispatch({
        type: 'LOGOUT'
      }); 
      return {
        error: true
      }
    }
    
    if (user.challengeName) {
      console.error(`Can't login, "${user.challengeName}" failed.`); 
      return {
        error: true
      }
    }
    let userPayload = {
      id: user.attributes.sub,
      jobtitle: user.attributes.email,
      avatar: user.picture,
      email: user.attributes.email,
      username: user.username, 
      worker: wrker,
      sessionToken: user.signInUserSession.idToken.jwtToken,
      profile, 
      metaData,
      roles
    };
    if(localSave){
      localStorage.setItem('hnh_user', window.btoa(JSON.stringify({email, password}))); 
    }else {
      localStorage.removeItem('hnh_user');
    }
    // console.log(userPayload)
    
    if(!shown){
      betaMessages();
      setShown(true);
    }
    dispatch({
      type: 'LOGIN',
      payload: {
        user: {...userPayload}
      }
    });
    return {
      error: false
    }
  };

  const logout = async () => {
    await Auth.signOut();
    // console.log("Cleared Local")
    // const remember = localStorage.getItem('hnh_user');
    // if(!remember)
    localStorage.clear();
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (email, password) => {
    await Auth.signUp({
      username: email,
      password,
      attributes: { email, "custom:isWorkerOnSignUp": 0, }
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username, code) => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username) => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordRecovery = async (username) => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const passwordReset = async (username, code, newPassword) => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Amplify',
        updateUserData,
        login,
        reFetchUserData,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
