import {
  Card,
  CardHeader,
  Grid,
  Divider,  
  useTheme, 
} from '@mui/material';
import { useTranslation } from 'react-i18next'; 
import Chart from 'react-apexcharts';
import React, { useState, useEffect } from 'react';
 
function GeneralSummary({data, type}) {
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect(() => {
    console.log(type, data)
    let dataPoints = [];
    for(let x = 0; x < data.length; x++){
      let obj = data[x];
      if(obj.orderData.state === 'cancelled'){
        // eslint-disable-next-line no-continue
        continue;
      }
      let summary = null
      let summaryobj = null; 
      let featAmnt = null;
      let orderTime = null; 
      if(type === "worker"){
        if(obj?.transactionData?.summary){
          summary = `{${obj.transactionData.summary.replaceAll('|', ',')}}`
          summaryobj = JSON.parse(summary); 
          featAmnt = summaryobj.Net_Profit; 
        }
      }else {
        // eslint-disable-next-line no-lonely-if
        if(obj?.transactionData?.amnt){
          featAmnt = obj.transactionData.amnt;
        }
      }
      if(obj?.orderData?.transaction?.metaData?.pickupData?.eventTimestamp)
        orderTime = obj.orderData.transaction.metaData.pickupData.eventTimestamp;

      // dataPoints.push({
      //   time: orderTime,
      //   value: featAmnt
      // })
      if(orderTime !== null && featAmnt !== null)
        dataPoints.push([orderTime,featAmnt])
    }
    setChartData([{...chartData[0], data: dataPoints }]);

  }, [data]);
  // eslint-disable-next-line no-unused-vars
  const [ chartOptions, setChartOptions] = useState({
    stroke: {
      curve: 'smooth',
      width: [0, 5]
    },
    theme: {
      mode: theme.palette.mode
    },
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    markers: {
      hover: {
        sizeOffset: 2
      },
      shape: 'circle',
      size: 6,
      strokeWidth: 3,
      strokeOpacity: 1,
      strokeColors: theme.colors.error.dark,
      colors: [theme.colors.alpha.white[100]]
    },
    colors: [theme.colors.warning.light, theme.colors.error.light],
    fill: {
      opacity: 1
    },
    states: {
      normal: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      },
      active: {
        filter: {
          type: 'none'
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        columnWidth: '28%'
      }
    }, 
    grid: {
      show: false
    },
    legend: {
      show: false
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      show: false,
      tickAmount: 6,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    }
  }); 
  // eslint-disable-next-line no-unused-vars
  const [ chartData, setChartData] = useState([
    {
      name: `${(type === "worker") ? 'Profit' : 'Cost'}`,
      type: 'column',
      data: []
    },
    // {
    //   name: 'Cancelled',
    //   type: 'line',
    //   data: [648, 745, 897, 743, 635, 842, 811, 696, 878, 987, 747, 731]
    // }
  ]);

  return (
    <Card>
      {chartData[0].data.length > 0 && <CardHeader title={ type === 'user' ? t('Spending Summary') : t('Profit Summary')} />}
      {chartData[0].data.length > 0 && <Divider />}
      <Grid container> 
        {chartData[0].data.length > 0 && <Grid item xs={12} md>
          {/* <Box
            sx={{
              p: 3,
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >  */}
            <Chart
              options={chartOptions}
              series={chartData}
              type="line" 
            />
          {/* </Box> */}
        </Grid>}
      </Grid>
    </Card>
  );
}

export default GeneralSummary;
