import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars-2';
import React, {useState, useEffect} from 'react';
import { Box, useTheme } from '@mui/material';

const Scrollbar = ({ changeVal = null, className, children, ...rest }) => {
  const theme = useTheme();
  // eslint-disable-next-line no-unused-vars
  const [scroll, setScroll] = useState(React.createRef());

  
  useEffect(() => {
    // console.log(changeVal, scroll.current.getValues());
    if(changeVal !== null)
      setTimeout(function() { 
        if(scroll && scroll.current)
          scroll.current.scrollToBottom(); 
      }, 1500);
      
  }, [changeVal]); 
  return (
    <Scrollbars
      autoHide
      ref={scroll} 
      onUpdate={() => {
        // console.log(scroll.current.getValues());
        // if(scroll.current)
        //   scroll.current.scrollToBottom()
      }}
      renderThumbVertical={() => {
        return (
          <Box
            sx={{
              width: 5,
              background: `${theme.colors.alpha.black[10]}`,
              borderRadius: `${theme.general.borderRadiusLg}`,
              transition: `${theme.transitions.create(['background'])}`,

              '&:hover': {
                background: `${theme.colors.alpha.black[30]}`
              }
            }}
          />
        );
      }}
      {...rest}
    >
      {children}
    </Scrollbars>
  );
};

Scrollbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Scrollbar;
