import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector 
} from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { configureStore, applyMiddleware, compose } from '@reduxjs/toolkit';
// import logger from "redux-logger";
import rootReducer from './rootReducer';

// const middleWares = [logger];
// eslint-disable-next-line no-unused-vars
// const composedEnhancers = compose(applyMiddleware(...middleWares));

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
