import { combineReducers} from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as userdeals } from 'src/slices/user_deals';
import { reducer as dealcreate } from 'src/slices/deal_create';
import { reducer as storeplatform } from 'src/slices/store_platform';
import { reducer as messengerchats } from 'src/slices/messenger_chats';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  userDeals: userdeals,
  dealCreate: dealcreate,
  storePlatform: storeplatform,
  messengerChats: messengerchats
});

export default rootReducer;
